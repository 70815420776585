<template>
  <div class="page">
    <Navbar :isBack="true" :title="$t('login.install')" />
    <div class="main">
      <h2>Thêm ứng dụng Desktop để bắt đầu trò chơi</h2>
      <div class="cell">Khởi động di động · Tối ưu hóa toàn màn hình</div>
      <div><img :src="imgSrc.gif" alt="" srcset="" /></div>
      <!-- <div class="cell">Giới hạn chữ ký tài khoản nhà phát triển Apple, cung cấp dịch vụ đánh dấu trang trên máy tính để bàn</div> -->
      <div>
        <div class="google-pop-text">
          Mở link bằng<img class="google-pop-safari" :src="imgSrc.browserImg" />
          <span>{{ imgSrc.browser }}</span>
        </div>
        <div class="google-pop-text">
          Thêm vào Màn hình chính là có thể trải nghiệm
        </div>
      </div>
      <div class="google-pop-btn">
        <van-button @click="copyBtn" color="#f25200" round block
          >Sao chép link</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: true,
      checked: "",
      showBottom: true,
    };
  },
  computed: {
    imgSrc() {
      let gameId = ["75", "120", "121"];
      let game_id = gameId.includes(localStorage.getItem("game_id"))
        ? localStorage.getItem("game_id")
        : "120";
      let safariImgSrc;
      if (game_id == "75") {
        safariImgSrc = {
          img: require(`@/assets/img/safari_120.png`),
          gif: require(`@/assets/120.gif`),
          browser: "Safari",
          browserImg: require(`@/assets/img/safari.png`),
        };
      }
      if (game_id && game_id !== "75") {
        const userAgent = navigator.userAgent.toLowerCase();
        const isAndroid = /android/.test(userAgent);
        if (isAndroid && Number(game_id) === 121) {
          safariImgSrc = {
            img: require(`@/assets/img/safari_${game_id}.png`),
            gif: require(`@/assets/${game_id}_google.gif`),
            browser: "Google",
            browserImg: require(`@/assets/img/google.png`),
          };
        } else {
          safariImgSrc = {
            img: require(`@/assets/img/safari_${game_id}.png`),
            gif: require(`@/assets/${game_id}.gif`),
            browser: "Safari",
            browserImg: require(`@/assets/img/safari.png`),
          };
        }
      }
      return safariImgSrc;
    },
  },
  created() {
    //判断是否是Safari浏览器
    if (this.isLaunchedFromHomeScreen()) {
      this.show = false;
      // 检查是否是从主屏幕启动
      if (Boolean(window.navigator.standalone)) {
        this.showBottom = false;
      } else {
        const lastShownTimestamp = localStorage.getItem(
          "popupLastShownTimestamp"
        );
        const now = new Date().getTime();
        let date =
          !lastShownTimestamp || now - lastShownTimestamp > 24 * 60 * 60 * 1000;
        this.showBottom = date ? true : false;
      }
    } else {
      this.showBottom = false;
      const hash = window.location.hash.substr(1); // 去掉开头的 "#"
      const params = new URLSearchParams(hash.split("?")[1]);
      const game_id = params.get("game_id"); // 获取 "type" 参数值
      const userAgent = navigator.userAgent.toLowerCase();
      const isAndroid = /android/.test(userAgent);
      if (game_id && isAndroid && Number(game_id) === 121) {
        this.show = false;
        Boolean(window.navigator.standalone)
          ? (this.showBottom = false)
          : (this.showBottom = true);
      } else {
        this.show = !this.isPc() ? true : false;
      }
    }
  },
  methods: {
    isLaunchedFromHomeScreen() {
      // 你可以通过检查特定的用户代理字符串或其他方式来判断是否是从主屏幕启动
      const userAgent = window.navigator.userAgent.toLowerCase();
      return (
        /safari/.test(userAgent) && !/chrome|chromium|crios/.test(userAgent)
      );
    },
    isPc() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isWindowsPhone = /windows phone/.test(userAgent);
      const isAndroid = /android/.test(userAgent);
      const isIOS = /iphone|ipad|ipod/.test(userAgent);
      return (!isWindowsPhone && !isIOS) || isAndroid;
    },

    handleClose() {
      if (this.checked) {
        localStorage.setItem("popupLastShownTimestamp", new Date().getTime());
      }
      this.show = false;
    },
    copyBtn() {
      // 创建一个临时的 textarea 元素
      const tempInput = document.createElement("textarea");
      const hash = window.location.hash.substr(1); // 去掉开头的 "#"
      const params = new URLSearchParams(hash.split("?")[1]);
      const gameId = params.get("game_id"); // 获取 "type" 参数值

      let game_id = localStorage.getItem("game_id") || gameId;
      let Gameurl = window.location.origin + "/#/login?game_id=" + game_id;
      tempInput.value = Gameurl;
      document.body.appendChild(tempInput);

      // 选择文本并复制到剪贴板
      tempInput.select();
      document.execCommand("copy");

      // 移除临时 textarea 元素
      document.body.removeChild(tempInput);
      this.$Msg("Copy Success");
    },
  },
};
</script>
<style scoped lang='scss'>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3rem 0.3rem;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  font-size: 0.25rem;
}
.cell {
  color: grey;
  margin: 0.3rem 0;
}
.google-pop-text {
  text-align: center;
  margin: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.google-pop-safari {
  width: 0.5rem;
}
.google-pop-btn {
  margin-top: 0.25rem;
  width: 90%;
}
</style>